<template>
  <div>
    <div class="mx-4 my-2"><small>Filtra per</small></div>

    <v-chip-group
      show-arrows
      class="px-4"
      active-class="primary--text"
      multiple
      @change="filter"
      v-model="selectedWarehouseClasses"
    >
      <v-chip
        small
        v-for="warehouseClass in warehouseClasses.values"
        :key="warehouseClass.id"
      >
        {{ warehouseClass.name }}
      </v-chip>
    </v-chip-group>
  </div>
</template>
<script>
export default {
  name: "WarehouseClassFilter",
  props: {
    filterTitle: { type: String, required: false, default: "Filtra per" },
    warehouseClasses: {
      type: Object,
      default: () => {
        return { values: [] };
      }
    }
  },
  methods: {
    filter() {
      const warehouseClassIds = this.selectedWarehouseClasses.map(
        warehouseClass => this.warehouseClasses.values[warehouseClass].id
      );
      let params = {};
      params[this.warehouseClasses.parameterName] = warehouseClassIds;
      this.$emit("filter", params);
    }
  },
  data() {
    return { selectedWarehouseClasses: [] };
  }
};
</script>
