<template>
  <v-card class="w-100 my-4">
    <small v-if="geoLocationEnabled" class="distance-to"
      >{{ calcDistance(warehouse) }} Km</small
    >
    <isOpenRightNow
      class="white--text"
      maxWidth="100"
      :serviceHours="warehouse.serviceHours"
    ></isOpenRightNow>

    <div class="address-info">
      <v-card-title>
        {{ warehouse.name || warehouse.address.addressName }}
      </v-card-title>
      <v-card-subtitle>
        {{ warehouse.address.address1 }},{{ warehouse.address.addressNumber }}
        {{ warehouse.address.postalcode }}
        {{ warehouse.address.city }}
        {{ warehouse.address.province }}
      </v-card-subtitle>
    </div>
    <v-slide-group show-arrows v-if="warehouseCardToShow">
      <v-slide-item
        v-for="wc in warehouseCardToShow"
        :key="wc.warehouseClassId"
      >
        <v-card-subtitle class="d-inline-block">
          <span class="d-block warehouse-class-group">
            {{ wc.warehouseClassGroup.name }} </span
          ><span
            class="d-block warehouse-class-name font-weight-bold primary--text"
          >
            {{ wc.name }}
          </span>
        </v-card-subtitle>
      </v-slide-item>
      <v-slide-item
        v-if="warehouse.warehouseClass.length > maxWareHouseClassToShow"
      >
        <v-card-subtitle class="d-inline-block">
          <span class="d-block warehouse-class-group"> altri servizi </span
          ><span
            class="d-block warehouse-class-name font-weight-bold primary--text"
            >+
            {{
              warehouse.warehouseClass.length - maxWareHouseClassToShow
            }}</span
          >
        </v-card-subtitle>
      </v-slide-item>
    </v-slide-group>

    <WarehouseHours :serviceHours="warehouse.serviceHours"></WarehouseHours>

    <v-card-actions class="d-flex justify-end">
      <v-btn
        size="small"
        color="primary darken-2"
        outlined
        :to="{
          name: 'WarehouseDetail',
          params: { warehouseId: warehouse.warehouseId }
        }"
      >
        Seleziona
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style scoped lang="scss"></style>
<script>
import IsOpenRightNow from "@/components/wordpress/isOpenRightNow.vue";
import WarehouseHours from "@/components/warehouse/WarehouseHours.vue";
export default {
  name: "WarehouseCard",
  components: { IsOpenRightNow, WarehouseHours },
  props: {
    warehouse: { type: Object, required: true },
    geoLocationEnabled: { type: Boolean, required: false, default: false }
  },
  data() {
    return { maxWareHouseClassToShow: 2 };
  },
  computed: {
    warehouseCardToShow() {
      return this.warehouse.warehouseClass?.slice(
        0,
        this.maxWareHouseClassToShow
      );
    }
  },
  methods: {
    toRadians(degree) {
      return degree * (Math.PI / 180);
    },
    distanceBetween(point1, point2) {
      const radius = 6371; // Radius of the Earth in km
      const lat1 = this.toRadians(point1[0]);
      const lat2 = this.toRadians(point2[0]);
      const deltaLat = this.toRadians(point2[0] - point1[0]);
      const deltaLng = this.toRadians(point2[1] - point1[1]);

      const a =
        Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
        Math.cos(lat1) *
          Math.cos(lat2) *
          Math.sin(deltaLng / 2) *
          Math.sin(deltaLng / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = radius * c;

      return d;
    },
    calcDistance(warehouse) {
      const point2 = [45.697196, 9.0355854];
      const wareHouseCoords = [
        warehouse.address.latitude,
        warehouse.address.longitude
      ];
      const distance = this.distanceBetween(wareHouseCoords, point2).toFixed(2);
      return distance;
    }
  },
  mounted() {}
};
</script>
