<template>
  <div class="warehouse-list">
    <breadcrumb
      :items="category.navigationTypeId != 0 ? breadcrumbs : breadcrumb"
    />

    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1 position-1"
    />
    <v-container fluid class="py-0">
      <category-title class="product-category-title" :category="category" />
      <v-row no-gutters>
        <v-col
          v-if="showListParsed"
          class="py-0 py-md-3"
          order="1"
          order-sm="1"
          cols="12"
          :sm="showListParsed ? '6' : '0'"
          :xl="showListParsed ? '6' : '0'"
        >
          <div class="mx-4 my-2">Cerca il tuo negozio e scopri i servizi</div>
          <AddressFilter
            v-if="google"
            :google="google"
            class="px-4"
            @addressToSearchChanged="addressToSearchChanged"
            @resetNearWarehouse="resetNearWarehouse"
          />
          <WarehouseClassFilter
            :warehouseClasses="warehouseClasses"
            @filter="applyFilter"
          ></WarehouseClassFilter>
          <!-- :height="mapHeight" -->
          <v-list class="warehouse-list-list" v-if="warehouseList.length > 0">
            <v-list-item
              v-for="warehouse in warehouseList"
              :key="warehouse.warehouseId"
              dense
            >
              <WarehouseCard :warehouse="warehouse"></WarehouseCard>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col
          class="py-0 py-md-3"
          order="2"
          order-sm="2"
          cols="12"
          :sm="showListParsed ? '6' : '0'"
          :xl="showListParsed ? '6' : '0'"
        >
          <GmapMap
            ref="mapRef"
            :center="{
              lat: addressToSearch ? addressToSearch.latitude : 44.3948,
              lng: addressToSearch ? addressToSearch.longitude : 8.9454
            }"
            :zoom="zoomLevel"
            :style="{ width: mapWidth }"
            :options="{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              disableDefaultUI: false,
              ...mapStyle
            }"
          >
            <GmapCluster :styles="clusterStyles">
              <GmapMarker
                :ref="'warehousMarker_' + warehouse.warehouseId"
                v-for="(warehouse, i) in warehouseList"
                :key="warehouse.warehouseId"
                :position="
                  google &&
                    new google.maps.LatLng(
                      warehouse.address.latitude,
                      warehouse.address.longitude
                    )
                "
                :clickable="true"
                icon="/pdv/pin.png"
                @click="toggleInfoWindow(warehouse, i)"
              />
            </GmapCluster>
            <gmap-info-window
              :options="infoOptions"
              :position="infoWindowPos"
              :opened="infoWinOpen"
              @closeclick.stop="infoWinOpen = false"
            >
              {{ selectedWarehouse }}
              Contenuto
            </gmap-info-window>
            <gmap-circle
              v-if="addressToSearch"
              :center="{
                lat: addressToSearch.latitude,
                lng: addressToSearch.longitude
              }"
              :radius="addressToSearch.distance"
              :visible="true"
              :options="{ fillOpacity: 0.3, strokeWeight: 0.3 }"
            >
            </gmap-circle>
          </GmapMap>
        </v-col>
      </v-row>
    </v-container>
    <category-block
      :target="category"
      position="position2"
      class="category-block position-2"
    />
    <category-block
      :target="category"
      position="position3"
      class="category-block position-3"
    />

    <v-container fluid>
      <ebsn-meta
        :target="category"
        path="category_info.FOOTER_DESCRIPTION"
        tag="div"
      />
    </v-container>
  </div>
</template>
<style lang="scss">
.vue-map-container {
  height: calc(100vh - 255px);
  position: sticky;
  top: 100px;
}
.warehouse-list-list {
  // height: calc(100vh - 235px);
  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: calc(100vh - 100px);
  }
  overflow-y: auto;
  .warehouse-class-group {
    font-size: 0.8rem;
  }
  .address-info {
    width: 78%;
  }
  .open-alert {
    position: absolute;
    right: 8px;
    top: 16px;
    padding: 2px 4px;
    font-size: 0.9rem;
  }
}
.distance-to {
  position: absolute;
  right: 8px;
  top: 50px;
}
.gm-style-iw .gm-style-iw {
  max-height: 180px !important;
}
.gm-style {
  .gm-style-iw-c {
    max-height: 180px !important;
    .gm-style-iw-d {
      max-height: 180px !important;
    }
  }
}
#infoWindowDialog {
  text-align: left;
  cursor: pointer;
  font-family: Archivo;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 150%;
  // color: var(--v-primary-base);

  h3 {
    font-size: 16px;
    text-transform: uppercase;
    color: var(--v-primary-base);
  }
}
</style>
<script>
import AddressFilter from "@/components/address/AddressFilter.vue";
import WarehouseClassFilter from "@/components/warehouse/WarehouseClassFilter.vue";

import WarehouseCard from "@/components/warehouse/WarehouseCard.vue";
import AddressService from "~/service/addressService";

import categoryMixins from "~/mixins/category";

import { gmapApi } from "vue2-google-maps";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";

export default {
  name: "WarehouseMap",
  components: {
    AddressFilter,
    GmapCluster,
    WarehouseClassFilter,
    WarehouseCard,
    CategoryTitle,
    Breadcrumb
  },
  props: {
    showList: { type: String, default: "false" },
    regionId: { type: String, required: false },
    provinceId: { type: String, required: false },
    parentAddressClassId: { type: String, required: false }
  },
  mixins: [categoryMixins],
  data() {
    return {
      showListParsed: true,
      warehouseList: [],
      facets: [],
      geoLocationEnabled: false,
      infoOptions: {
        content: "",
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      infoWindowPos: null,
      infoWinOpen: false,
      selectedWarehouse: null,
      selectedRegionId: null,

      addressToSearch: null,
      clusterStyles: [
        {
          textColor: "#FFFFFF",
          url: "/pdv/map_clusters/m1.png",
          height: 52,
          width: 53
        },
        {
          textColor: "#FFFFFF",
          url: "/pdv/map_clusters/m2.png",
          height: 55,
          width: 56
        },
        {
          textColor: "#FFFFFF",
          url: "/pdv/map_clusters/m3.png",
          height: 65,
          width: 66
        },
        {
          textColor: "#FFFFFF",
          url: "/pdv/map_clusters/m4.png",
          height: 77,
          width: 78
        },
        {
          textColor: "#FFFFFF",
          url: "/pdv/map_clusters/m5.png",
          height: 89,
          width: 90
        }
      ],
      mapStyle: {
        styles: [
          {
            elementType: "geometry",
            stylers: [
              {
                color: "#f5f5f5"
              }
            ]
          },
          {
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#616161"
              }
            ]
          },
          {
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#f5f5f5"
              }
            ]
          },
          {
            featureType: "administrative.land_parcel",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#bdbdbd"
              }
            ]
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
              {
                color: "#eeeeee"
              }
            ]
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#757575"
              }
            ]
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
              {
                color: "#e5e5e5"
              }
            ]
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff"
              }
            ]
          },
          {
            featureType: "road.arterial",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#757575"
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [
              {
                color: "#dadada"
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#616161"
              }
            ]
          },
          {
            featureType: "road.local",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e"
              }
            ]
          },
          {
            featureType: "transit.line",
            elementType: "geometry",
            stylers: [
              {
                color: "#e5e5e5"
              }
            ]
          },
          {
            featureType: "transit.station",
            elementType: "geometry",
            stylers: [
              {
                color: "#eeeeee"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                color: "#c9c9c9"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e"
              }
            ]
          }
        ]
      }
    };
  },
  computed: {
    google: gmapApi,
    mapWidth() {
      return "auto";
    },
    mapHeight() {
      return this.$vuetify.breakpoint.xsOnly
        ? "650px"
        : window.innerHeight - 350 + "px";
    },
    pageContent() {
      return this.page && this.page.content ? this.page.content.rendered : "";
    },
    pageTitle() {
      return this.page && this.page.title ? this.page.title.rendered : "";
    },
    zoomLevel() {
      if (this.addressToSearch) {
        return this.addressToSearch.distance == 10000 ? 12 : 10;
      } else {
        return this.$vuetify.breakpoint.xsOnly ? 11 : 11;
      }
    },
    warehouseClasses() {
      return this.facets?.filter(facet => facet.name == "warehouseClass")[0];
    }
  },
  methods: {
    resetNearWarehouse() {
      this.addressToSearch = null;
    },
    async addressToSearchChanged(addressToSearch) {
      this.addressToSearch = addressToSearch;
      await this.findWarehouse();
    },
    async findWarehouse(additionalSearchParams = null) {
      let searchParams = {
        region_id: this.regionId,
        province_id: this.provinceId,
        ...additionalSearchParams
      };
      if (this.addressToSearch) {
        searchParams["parent_latitude"] = this.addressToSearch.latitude;
        searchParams["parent_longitude"] = this.addressToSearch.longitude;
        searchParams["parent_distance"] = this.addressToSearch.distance;
      }
      let data = await AddressService.findWarehouse(searchParams);
      this.warehouseList = data.warehouses;
      this.facets = data.facets;

      let userLocation = await this.getUserLocation();
      if (userLocation) {
        this.geoLocationEnabled = true;
        this.sortWarehousesByLocation(userLocation);
      } else {
        this.geoLocationEnabled = false;
      }
    },
    applyFilter(warehouseClasses) {
      this.findWarehouse(warehouseClasses);
    },
    async getUserLocation() {
      return new Promise((resolve, reject) => {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(position => {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;
            resolve({ lat, lng });
          }, reject);
        } else {
          resolve(false);
        }
      });
    },
    animateMarker(warehouse, status) {
      if (status) {
        this.$refs[
          "warehousMarker_" + warehouse.warehouseId
        ][0].animation = this.google.maps.Animation.BOUNCE;
      } else {
        this.$refs[
          "warehousMarker_" + warehouse.warehouseId
        ][0].animation = null;
      }
    },
    toggleInfoWindow(warehouse) {
      this.infoWindowPos = {
        lat: warehouse.address.latitude,
        lng: warehouse.address.longitude
      };
      let phoneNumber = warehouse.metaData.warehouse_locator?.PHONE || " -- ";

      let infoWindowBtn = `<a class='v-btn v-btn--text primary--text v-size--small' target='system' href='https://www.google.it/maps/dir//${warehouse.name}@/${warehouse.address.latitude},${warehouse.address.longitude}'>
            COME ARRIVARE
          </a><span width="10px"></span> <a class='v-btn primary v-size--small' href='/supermercati/${warehouse.warehouseId}'>
            VAI ALLA SCHEDA
          </a>`;

      this.infoOptions.content = `<div id='infoWindowDialog' style='font-family: Open Sans, arial, sans-serif'>
          <h3 class='d-flex align-center' style='line-height: 1.2rem;'>
            ${warehouse.name || warehouse.address.addressName}
          </h3>
          <div class='mb-1'>
            ${warehouse.address.address1} ${
        warehouse.address.addressNumber
      } <br/>
            ${warehouse.address.city} ( ${warehouse.address.province} ) ${
        warehouse.address.postalcode
      } <br/>
            <strong>Telefono:</strong> ${phoneNumber} <br/>
          </div>
          ${infoWindowBtn}
        </div>`;

      //check if its the same marker that was selected if yes toggle
      if (
        this.selectedWarehouse &&
        this.selectedWarehouse.warehouseId == warehouse.warehouseId
      ) {
        this.infoWinOpen = !this.infoWinOpen;
      }

      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.selectedWarehouse = warehouse;
      }
    },
    sortWarehousesByLocation(location) {
      const sortedWarehouses = this.warehouseList.sort((a, b) => {
        return (
          this.calcDistance(a, location.lat, location.long) -
          this.calcDistance(b, location.lat, location.long)
        );
      });
      this.warehouseList = sortedWarehouses;
    },
    distanceBetween(point1, point2) {
      const radius = 6371; // Radius of the Earth in km
      const lat1 = this.toRadians(point1[0]);
      const lat2 = this.toRadians(point2[0]);
      const deltaLat = this.toRadians(point2[0] - point1[0]);
      const deltaLng = this.toRadians(point2[1] - point1[1]);

      const a =
        Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
        Math.cos(lat1) *
          Math.cos(lat2) *
          Math.sin(deltaLng / 2) *
          Math.sin(deltaLng / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = radius * c;

      return d;
    },
    calcDistance(warehouse, lat = 45.697196, long = 9.0355854) {
      const point2 = [lat, long];
      const wareHouseCoords = [
        warehouse.address.latitude,
        warehouse.address.longitude
      ];
      const distance = this.distanceBetween(wareHouseCoords, point2).toFixed(2);
      return distance;
    },
    toRadians(degree) {
      return degree * (Math.PI / 180);
    }
  },
  mounted() {
    this.findWarehouse();
  }
};
</script>
