var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"warehouse-list"},[_c('breadcrumb',{attrs:{"items":_vm.category.navigationTypeId != 0 ? _vm.breadcrumbs : _vm.breadcrumb}}),_c('category-block',{staticClass:"category-block category-block-1 position-1",attrs:{"target":_vm.category,"position":"position1"}}),_c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('category-title',{staticClass:"product-category-title",attrs:{"category":_vm.category}}),_c('v-row',{attrs:{"no-gutters":""}},[(_vm.showListParsed)?_c('v-col',{staticClass:"py-0 py-md-3",attrs:{"order":"1","order-sm":"1","cols":"12","sm":_vm.showListParsed ? '6' : '0',"xl":_vm.showListParsed ? '6' : '0'}},[_c('div',{staticClass:"mx-4 my-2"},[_vm._v("Cerca il tuo negozio e scopri i servizi")]),(_vm.google)?_c('AddressFilter',{staticClass:"px-4",attrs:{"google":_vm.google},on:{"addressToSearchChanged":_vm.addressToSearchChanged,"resetNearWarehouse":_vm.resetNearWarehouse}}):_vm._e(),_c('WarehouseClassFilter',{attrs:{"warehouseClasses":_vm.warehouseClasses},on:{"filter":_vm.applyFilter}}),(_vm.warehouseList.length > 0)?_c('v-list',{staticClass:"warehouse-list-list"},_vm._l((_vm.warehouseList),function(warehouse){return _c('v-list-item',{key:warehouse.warehouseId,attrs:{"dense":""}},[_c('WarehouseCard',{attrs:{"warehouse":warehouse}})],1)}),1):_vm._e()],1):_vm._e(),_c('v-col',{staticClass:"py-0 py-md-3",attrs:{"order":"2","order-sm":"2","cols":"12","sm":_vm.showListParsed ? '6' : '0',"xl":_vm.showListParsed ? '6' : '0'}},[_c('GmapMap',{ref:"mapRef",style:({ width: _vm.mapWidth }),attrs:{"center":{
            lat: _vm.addressToSearch ? _vm.addressToSearch.latitude : 44.3948,
            lng: _vm.addressToSearch ? _vm.addressToSearch.longitude : 8.9454
          },"zoom":_vm.zoomLevel,"options":{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUI: false,
            ..._vm.mapStyle
          }}},[_c('GmapCluster',{attrs:{"styles":_vm.clusterStyles}},_vm._l((_vm.warehouseList),function(warehouse,i){return _c('GmapMarker',{key:warehouse.warehouseId,ref:'warehousMarker_' + warehouse.warehouseId,refInFor:true,attrs:{"position":_vm.google &&
                  new _vm.google.maps.LatLng(
                    warehouse.address.latitude,
                    warehouse.address.longitude
                  ),"clickable":true,"icon":"/pdv/pin.png"},on:{"click":function($event){return _vm.toggleInfoWindow(warehouse, i)}}})}),1),_c('gmap-info-window',{attrs:{"options":_vm.infoOptions,"position":_vm.infoWindowPos,"opened":_vm.infoWinOpen},on:{"closeclick":function($event){$event.stopPropagation();_vm.infoWinOpen = false}}},[_vm._v(" "+_vm._s(_vm.selectedWarehouse)+" Contenuto ")]),(_vm.addressToSearch)?_c('gmap-circle',{attrs:{"center":{
              lat: _vm.addressToSearch.latitude,
              lng: _vm.addressToSearch.longitude
            },"radius":_vm.addressToSearch.distance,"visible":true,"options":{ fillOpacity: 0.3, strokeWeight: 0.3 }}}):_vm._e()],1)],1)],1)],1),_c('category-block',{staticClass:"category-block position-2",attrs:{"target":_vm.category,"position":"position2"}}),_c('category-block',{staticClass:"category-block position-3",attrs:{"target":_vm.category,"position":"position3"}}),_c('v-container',{attrs:{"fluid":""}},[_c('ebsn-meta',{attrs:{"target":_vm.category,"path":"category_info.FOOTER_DESCRIPTION","tag":"div"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }